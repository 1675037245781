import Entity from '../entity'

export default class Sticker extends Entity<number>{
    page_type_id: number;
    printer_id: number;
    number: number;
    name: string;
    template: string;
    epl: string;
    format: string;
    active: boolean;
}


